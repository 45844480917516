<template>
  <div class="infoWrap">
    <Row :gutter="30">
      <Col :md="12" :xs="24" v-if="info.cover">
        <img class="cover" :src="getImageUrl(info.cover)" alt="">
      </Col>
      <Col :md="12" :xs="24">
        <div>
          <p class="name">{{info.name}}</p>
          <p class="info">参评类别：{{info.type || '-'}}<template v-if="info.subType"> - {{info.subType}}</template></p>
          <!-- <p class="info">所属参评单位：{{info.regInfo.name || '-'}}</p> -->
          <p class="info">完成时间：{{info.completionDate || '-'}}</p>
          <p class="info">项目所在地：{{info.address}}</p>
          <p class="info">项目规模：{{info.scale || '-'}}</p>
          <p class="info">功能类型：{{info.functionType || '-'}}</p>
        </div>
      </Col>
    </Row>
    <div class="clearfix item">
      <div class="label">项目综述</div>
      <div class="content">
        <!-- <p>{{info.description}}</p> -->
        <Ellipsis v-if="info.description" :content="info.description"></Ellipsis>
        <Button v-if="info.descriptionFile&&info.descriptionFile.length>0" class="imgBtn" shape="circle" @click="showImg('descriptionFile', '项目综述')">查看图片</Button>
      </div>
    </div>
    <div class="clearfix item">
      <div class="label">创新性</div>
      <div class="content">
        <!-- <p>{{info.creativity}}</p> -->
        <Ellipsis v-if="info.creativity" :content="info.creativity"></Ellipsis>
        <Button v-if="info.creativityFile&&info.creativityFile.length>0" class="imgBtn" shape="circle" @click="showImg('creativityFile', '创新性')">查看图片</Button>
      </div>
    </div>
    <div class="clearfix item">
      <div class="label">行业引领</div>
      <div class="content">
        <!-- <p>{{info.industryLeading}}</p> -->
        <Ellipsis v-if="info.industryLeading" :content="info.industryLeading"></Ellipsis>
        <Button v-if="info.industryLeadingFile&&info.industryLeadingFile.length>0" class="imgBtn" shape="circle" @click="showImg('industryLeadingFile', '行业引领')">查看图片</Button>
      </div>
    </div>
    <div class="clearfix item">
      <div class="label">健康环保</div>
      <div class="content">
        <!-- <p>{{info.healthyProtection}}</p> -->
        <Ellipsis v-if="info.healthyProtection" :content="info.healthyProtection"></Ellipsis>
        <Button v-if="info.healthyProtectionFile&&info.healthyProtectionFile.length>0" class="imgBtn" shape="circle" @click="showImg('healthyProtectionFile', '健康环保')">查看图片</Button>
      </div>
    </div>
    <div class="clearfix item">
      <div class="label">综合效益</div>
      <div class="content">
        <!-- <p>{{info.benefits}}</p> -->
        <Ellipsis v-if="info.benefits" :content="info.benefits"></Ellipsis>
        <Button v-if="info.benefitsFile&&info.benefitsFile.length>0" class="imgBtn" shape="circle" @click="showImg('benefitsFile', '综合效益')">查看图片</Button>
      </div>
    </div>
    <div class="clearfix item">
      <div class="label">宣传视频</div>
      <div class="content">
        <a v-if="info.video" :href="info.video" class="colorGreen" target="_blank">{{info.video}}</a>
        <template v-else>-</template>
      </div>
    </div>
    <!-- <div class="clearfix item" v-if="info.designPictures&&info.designPictures.length>0">
      <div class="label">设计图</div>
      <div class="content">
        <Button class="imgBtn" shape="circle" @click="showImg('designPictures', '设计图')">查看图片</Button>
      </div>
    </div>
    <div class="clearfix item">
      <div class="label">实景/实物图</div>
      <div class="content">
        <Row :gutter="15">
          <Col :md="6" :xs="12" v-for="(i,j) in info.realPictures" :key="i.id">
            <div class="imgWrap">
              <img class="img" :src="getImageUrl(i.path, 'square-small')" @load="gCenterImg" alt="">
              <div class="mask"><span class="bigBtn" @click="showImg('realPictures', '实景/实物', j)"></span></div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div class="clearfix item">
      <div class="label">重点突出创新内容的图片</div>
      <div class="content">
        <Row :gutter="15">
          <Col :md="6" :xs="12" v-for="(i,j) in info.innovatePictures" :key="i.id">
            <div class="imgWrap">
              <img class="img" :src="getImageUrl(i.path, 'square-small')" @load="gCenterImg" alt="">
              <div class="mask"><span class="bigBtn" @click="showImg('innovatePictures', '重点突出创新内容的', j)"></span></div>
            </div>
          </Col>
        </Row>
      </div>
    </div> -->
    <div class="item">
      <Tabs type="card">
        <TabPane label="实景/实物图">
          <Row :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.realPictures" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('realPictures', '实景/实物', j)"></span></div>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane label="重点突出创新内容的图片">
          <Row :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.innovatePictures" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('innovatePictures', '重点突出创新内容的', j)"></span></div>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane label="竣工验收文件">
          <Row v-if="info.acceptanceImg&&info.acceptanceImg.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.acceptanceImg" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('acceptanceImg', '竣工验收文件', j)"></span></div>
              </div>
            </Col>
          </Row>
          <template v-if="info.acceptancePdf&&info.acceptancePdf.length>0">
            <p v-for="p in info.acceptancePdf" :key="p.id">
              <a :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            </p>
          </template>
          <p class="empty" v-if="!info.acceptanceFile||info.acceptanceFile&&info.acceptanceFile.length==0">没有上传文件</p>
        </TabPane>
        <TabPane label="设计图">
          <Row v-if="info.designPictures&&info.designPictures.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.designPictures" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('designPictures', '设计图', j)"></span></div>
              </div>
            </Col>
          </Row>
          <p class="empty" v-else>没有上传文件</p>
        </TabPane>
        <TabPane label="产品执行标准">
          <Row v-if="info.standardImg&&info.standardImg.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.standardImg" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('standardImg', '产品执行标准', j)"></span></div>
              </div>
            </Col>
          </Row>
          <template v-if="info.standardPdf&&info.standardPdf.length>0">
            <p v-for="p in info.standardPdf" :key="p.id">
              <a :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            </p>
          </template>
          <p class="empty" v-if="!info.standardFile||info.standardFile&&info.standardFile.length==0">没有上传文件</p>
        </TabPane>
        <TabPane label="专利技术">
          <Row v-if="info.patentImg&&info.patentImg.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.patentImg" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('patentImg', '专利技术', j)"></span></div>
              </div>
            </Col>
          </Row>
          <template v-if="info.patentPdf&&info.patentPdf.length>0">
            <p v-for="p in info.patentPdf" :key="p.id">
              <a :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            </p>
          </template>
          <p class="empty" v-if="!info.patentFile||info.patentFile&&info.patentFile.length==0">没有上传文件</p>
        </TabPane>
        <TabPane label="科研成果">
          <Row v-if="info.achievementsImg&&info.achievementsImg.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.achievementsImg" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('achievementsImg', '科研成果', j)"></span></div>
              </div>
            </Col>
          </Row>
          <template v-if="info.achievementsPdf&&info.achievementsPdf.length>0">
            <p v-for="p in info.achievementsPdf" :key="p.id">
              <a :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            </p>
          </template>
          <p class="empty" v-if="!info.achievementsFile||info.achievementsFile&&info.achievementsFile.length==0">没有上传文件</p>
        </TabPane>
        <TabPane label="项目所获奖励">
          <Row v-if="info.rewardImg&&info.rewardImg.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.rewardImg" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('rewardImg', '项目所获奖励', j)"></span></div>
              </div>
            </Col>
          </Row>
          <template v-if="info.rewardPdf&&info.rewardPdf.length>0">
            <p v-for="p in info.rewardPdf" :key="p.id">
              <a :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            </p>
          </template>
          <p class="empty" v-if="!info.rewardFile||info.rewardFile&&info.rewardFile.length==0">没有上传文件</p>
        </TabPane>
        <TabPane label="用户评价报告">
          <Row v-if="info.evaluateImg&&info.evaluateImg.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.evaluateImg" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('evaluateImg', '用户评价报告', j)"></span></div>
              </div>
            </Col>
          </Row>
          <template v-if="info.evaluatePdf&&info.evaluatePdf.length>0">
            <p v-for="p in info.evaluatePdf" :key="p.id">
              <a :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            </p>
          </template>
          <p class="empty" v-if="!info.evaluateFile||info.evaluateFile&&info.evaluateFile.length==0">没有上传文件</p>
        </TabPane>
        <TabPane label="推荐单位意见">
          <Row v-if="info.recommendImg&&info.recommendImg.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.recommendImg" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('recommendImg', '推荐单位意见', j)"></span></div>
              </div>
            </Col>
          </Row>
          <template v-if="info.recommendPdf&&info.recommendPdf.length>0">
            <p v-for="p in info.recommendPdf" :key="p.id">
              <a :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            </p>
          </template>
          <p class="empty" v-if="!info.recommendFile||info.recommendFile&&info.recommendFile.length==0">没有上传文件</p>
        </TabPane>
        <TabPane label="行业内类似奖项">
          <Row v-if="info.prizeImg&&info.prizeImg.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.prizeImg" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('prizeImg', '行业内类似奖项', j)"></span></div>
              </div>
            </Col>
          </Row>
          <template v-if="info.prizePdf&&info.prizePdf.length>0">
            <p v-for="p in info.prizePdf" :key="p.id">
              <a :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            </p>
          </template>
          <p class="empty" v-if="!info.prizeFile||info.prizeFile&&info.prizeFile.length==0">没有上传文件</p>
        </TabPane>
        <TabPane label="其它证明材料">
          <Row v-if="info.methodImg&&info.methodImg.length>0" :gutter="15">
            <Col :md="6" :xs="12" v-for="(i,j) in info.methodImg" :key="i.id">
              <div class="imgWrap">
                <img class="img" :src="getImageUrl(i.path, 'rectangle-small')" @load="gCenterImg" alt="">
                <div class="mask"><span class="bigBtn" @click="showImg('methodImg', '其它证明材料', j)"></span></div>
              </div>
            </Col>
          </Row>
          <template v-if="info.methodPdf&&info.methodPdf.length>0">
            <p v-for="p in info.methodPdf" :key="p.id">
              <a :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            </p>
          </template>
          <p class="empty" v-if="!info.methodFile||info.methodFile&&info.methodFile.length==0">没有上传文件</p>
        </TabPane>
      </Tabs>  
    </div>
    <div class="btnWrap">
      <Button type="text" class="textBtn" @click="changeProject('prev')"><Icon type="ios-arrow-back" />上一个</Button>
      <template v-if="info.scoreInfo">
        <Button class="defaultBtn submitBtn" shape="circle" @click="resetScore('reset')">重新评分</Button>
        <Button class="defaultBtn submitBtn" shape="circle" @click="resetScore('edit')">修改评分</Button>
      </template>
      <Button v-else class="defaultBtn submitBtn" shape="circle" @click="showScore">{{isScore?'隐藏':''}}评分</Button>
      <Button type="text" class="textBtn" @click="changeProject('next')">下一个<Icon type="ios-arrow-forward" /></Button>
    </div>

    <div v-if="isScore" id="score" class="scoreWrap">
      <Divider class="orangeDivider">创新性 50分</Divider>
      <p class="subTitle">创新性的应用及创新程度 15分</p>
      <div class="scoreText">
        <div class="note">评分标准</div>
        <p>a类：创新成果具有重大突破且完全自主创新（10分＜X≤15分）</p>
        <p>b类：有明显的突破和创新（5分＜X≤10分）</p>
        <p>c类：创新程度一般（0分＜X≤5分）</p>
      </div>
      <div class="score bdBtm">
        <div class="note">评审得分</div>
        <div class="scoreRight">
          <div class="scoreItem" v-for="i in (1,15)" :key="i">
            <Tooltip :content="i" theme="light">
              <div class="bgItem" :class="{'bgItemActive': i<=score.score1}" @click="changeScore('score1', i)"></div>
            </Tooltip>
          </div>
          <div class="line cLine five leftZero">c类</div>
          <div class="line bLine five leftFive">b类</div>
          <div class="line aLine five leftTen">a类</div>  
        </div>
        <div class="result">{{score.score1||0}}分</div>
      </div>
      <p class="subTitle">创新点对技术进步的贡献 15分</p>
      <div class="scoreText">
        <div class="note">评分标准</div>
        <p>a类：达到国际领先（15分）</p>
        <p>b类：达到国际先进（12分≤X＜15分）</p>
        <p>c类：达到国内领先（10分≤X＜12分）</p>
        <p>d类：达到国内先进（5分≤X＜10分）</p>
        <p>e类：其他（0分≤X＜5分）</p>
      </div>
      <div class="score bdBtm">
        <div class="note">评审得分</div>
        <div class="scoreRight">
          <div class="scoreItem" v-for="i in (1,15)" :key="i">
            <Tooltip :content="i" theme="light">
              <div class="bgItem" :class="{'bgItemActive': i<=score.score2}" @click="changeScore('score2', i)"></div>
            </Tooltip>
          </div>
          <div class="line eLine four leftZero">e类</div>
          <div class="line dLine five leftFour">d类</div>
          <div class="line cLine two leftNine">c类</div>
          <div class="line bLine three leftEleven">b类</div>
          <div class="line aLine one leftFourteen">a类</div>  
        </div>
        <div class="result">{{score.score2||0}}分</div>
      </div>
      <p class="subTitle">获科技或技术创新奖 20分</p>
      <div class="scoreText">
        <div class="note">评分标准</div>
        <p>a类：获得国家科技进步一等奖（20分）；</p>
        <p>b类：获得国家科技进步二等奖或省级科技进步一等奖（15分≤X＜20分）</p>
        <p>c类：省级科技进步二等奖或省部级以上奖项（詹天佑、鲁班奖、国家优质工程等）（10分≤X＜15分）</p>
        <p>d类：省级科技进步三等奖或国家级BIM成果奖或发明专利（5分≤X＜10分）</p>
        <p>e类：其他成果（工法、省级BIM奖项、实用新型专利等）（0分≤X＜5分））</p>
      </div>
      <div class="score">
        <div class="note">评审得分</div>
        <div class="scoreRight">
          <div class="scoreItem" v-for="i in (1,20)" :key="i">
            <Tooltip :content="i" theme="light">
              <div class="bgItem" :class="{'bgItemActive': i<=score.score3}" @click="changeScore('score3', i)"></div>
            </Tooltip>
          </div>
          <div class="line eLine four leftZero">e类</div>
          <div class="line dLine five leftFour">d类</div>
          <div class="line cLine five leftNine">c类</div>
          <div class="line bLine five leftFourteen">b类</div>
          <div class="line aLine one leftNineteen">a类</div>  
        </div>
        <div class="result">{{score.score3||0}}分</div>
      </div>
      <Divider class="orangeDivider">技术推广程度及行业引领 20分</Divider>
      <div class="scoreText">
        <div class="note">评分标准</div>
        <p>a类：创新技术已在行业内实施，作用和影响力大，国际或国内市场推广应用面大，市场应用前景很好，成熟度较高，行业引领作用强（15分＜X≤20分）</p>
        <p>b类：创新技术已在行业内实施，作用和影响力较大，国内市场有一定的推广面，市场应用前景好，成熟度高，行业引领作用领先（5分＜X≤15分）</p>
        <p>c类：创新技术已在行业内实施，作用和影响力较小，国内市场有推广面范围小，市场应用前景一般，成熟度一般，行业引领作用一般（0分＜X≤5分）</p>
      </div>
      <div class="score">
        <div class="note">评审得分</div>
        <div class="scoreRight">
          <div class="scoreItem" v-for="i in (1,20)" :key="i">
            <Tooltip :content="i" theme="light">
              <div class="bgItem" :class="{'bgItemActive': i<=score.score4}" @click="changeScore('score4', i)"></div>
            </Tooltip>
          </div>
          <div class="line cLine five leftZero">c类</div>
          <div class="line bLine ten leftFive">b类</div>
          <div class="line aLine five leftFifteen">a类</div>  
        </div>
        <div class="result">{{score.score4||0}}分</div>
      </div>
      <Divider class="orangeDivider">健康环保、绿色（智能）建造 20分</Divider>
      <div class="scoreText">
        <div class="note">评分标准</div>
        <p>a类：通过绿色建筑（材）三星认证（20分）</p>
        <p>b类：通过绿色建筑（材）二星认证或获住建部绿色施工科技示范工程或绿色设计（施工、建造）竞赛活动一等成果或智能建筑设计大赛一等成果（15分≤X＜20分）</p>
        <p>c类：获省级绿色施工科技示范工程或绿色设计（施工、建造）竞赛活动二等成果或智能建筑设计大赛二等成果（（10分≤X＜15分）</p>
        <p>d类：获绿色设计（施工、建造）竞赛活动三等成果或智能建筑设计大赛三等成果或省级绿色（智慧）建造水平评价（5分≤X＜10分）</p>
        <p>e类：其他（0分≤X＜5分）</p>
      </div>
      <div class="score">
        <div class="note">评审得分</div>
        <div class="scoreRight">
          <div class="scoreItem" v-for="i in (1,20)" :key="i">
            <Tooltip :content="i" theme="light">
              <div class="bgItem" :class="{'bgItemActive': i<=score.score5}" @click="changeScore('score5', i)"></div>
            </Tooltip>
          </div>
          <div class="line eLine four leftZero">e类</div>
          <div class="line dLine five leftFour">d类</div>
          <div class="line cLine five leftNine">c类</div>
          <div class="line bLine five leftFourteen">b类</div>
          <div class="line aLine one leftNineteen">a类</div>  
        </div>
        <div class="result">{{score.score5||0}}分</div>
      </div>
      <Divider class="orangeDivider">综合效益 10分</Divider>
      <div class="scoreText">
        <div class="note">评分标准</div>
        <p>1.经济效益:居全国同行业同类型工程领先水平</p>
        <p>2.社会效益:使用方的反馈意见，是否赢得社会好评</p>
      </div>
      <div class="score">
        <div class="note">评审得分</div>
        <div class="scoreRight">
          <div class="scoreItem" v-for="i in (1,10)" :key="i">
            <Tooltip :content="i" theme="light">
              <div class="bgItem" :class="{'bgItemActive': i<=score.score6}" @click="changeScore('score6', i)"></div>
            </Tooltip>
          </div>
        </div>
        <div class="result">{{score.score6||0}}分</div>
      </div>
      <div class="totalResult">总得分&nbsp;&nbsp;&nbsp;&nbsp;{{getTotalScore}}分</div>
      <div class="scoreText">
        <div class="note">专家点评（选填）</div>
        <Input v-model="score.comment" type="textarea" placeholder="请输入综合评价信息，限500字符。" :autosize="{minRows:5}" maxlength="500" show-word-limit></Input>
      </div>
      <div class="btnWrap">
        <Button class="defaultBtn submitBtn" :loading="submitting" shape="circle" @click="submitScore">提交评分</Button>
      </div>
    </div>

    <!-- 查看图片 -->
    <Modal class="imgModal" v-model="isShow" :title="`${modalTitle}图片`" footer-hide fullscreen :mask-closable="false">
      <Carousel v-if="isShow" v-model="index" :height="screenHeight" arrow="always" radius-dot>
        <CarouselItem v-for="i in imgList" :key="i.id">
          <img class="img" :src="getImageUrl(i.path)" @load="gCenterImg($event, 2)" alt="">
        </CarouselItem>
      </Carousel>
    </Modal>
    <!-- 评分提交成功 -->
    <Modal v-model="isSuccess" title="提交成功" footer-hide :mask-closable="false">
      <p class="center">提交评分成功！</p>
      <div class="modalBtnWrap">
        <Button class="orangeBtn" shape="circle" @click="backto">返回首页</Button>
        <Button class="orangeBtn" shape="circle" @click="hide">继续评分</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Service from '@/api/user'
import Ellipsis from "@/components/Ellipsis.vue";
export default {
  components: {
    Ellipsis
  },
  data(){
    return{
      info: { cover: null},
      isShow: false,
      imgList: [],
      modalTitle: '',
      screenHeight: 200,
      index: 0,
      isScore: false,
      score: {
        score1: 0,
        score2: 0,
        score3: 0,
        score4: 0,
        score5: 0,
        score6: 0
      },
      submitting: false,
      isSuccess: false
    }
  },
  mounted() {
    if (this.gIsLogin()) {
      this.getInfo(this.$route.query.id);
      let height = document.documentElement.clientHeight
      this.screenHeight = height - 83
      if(window.history && window.history.pushState){
        // 给 popstate 绑定一个方法 监听页面刷新
        window.addEventListener('popstate', this.backChange, false);
      }
    } else {
      this.$router.push('/login');
    }
  },
  computed:{
    getTotalScore(){
      return this.score.score1 + this.score.score2 + this.score.score3 + this.score.score4 + this.score.score5 + this.score.score6
    }
  },
  destroyed(){
    window.removeEventListener('popstate', this.backChange, false)
  },
  methods: {
    // 重新评分/修改评分
    resetScore(key){
      let content = null
      if(key=='reset'){
        content = '重新打分，将会清零上⼀次的打分记录，确定要执⾏此操作吗？'
      }
      if(key=='edit'){
        content = '修改打分，将会覆盖上⼀次的打分记录，确定要执⾏此操作吗？'
      }
      let _this = this
      this.$Modal.confirm({
        title: "提示",
        content,
        onOk: function() {
          if(key=='reset'){
            _this.score = {
              score1: 0,
              score2: 0,
              score3: 0,
              score4: 0,
              score5: 0,
              score6: 0
            }
          }
          _this.$nextTick(function () {
            let el = document.getElementById('score')
            window.scrollTo({"behavior":"smooth","top":el.offsetTop});
          })
        }
      })
    },
    // 监测到浏览器回退按钮执行操作
    backChange(){
      if(this.isShow){
        this.isShow = false
      }
    },
    // 返回首页
    backto(){
      this.isSuccess = false
      this.$nextTick(()=>{
        this.$router.push("/review/list");
      })
    },
    // 继续评分
    hide(){
      this.isSuccess = false
      this.changeProject('next')
    },
    // 提交评分
    submitScore(){
      // console.log(this.score)
      this.submitting = true;
      this.score.projectId = this.$route.query.id;
      Service.saveCompetitionScore(this.score)
        .then(res => {
          if (res.errorNo == 0) {
            // 提交成功执行
            this.isSuccess = true;
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.submitting = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.submitting = false;
        });
    },
    // 打分
    changeScore(key, score){
      this.score[key] = score
    },
    // 上一个，下一个
    changeProject(key){
      if(key=='prev'&&!this.info.prevProjectId){
        this.$Message.info('已是第一个，没有更多项目了')
        return
      }
      if(key=='next'&&!this.info.nextProjectId){
        this.$Message.info('已是最后一个，没有更多项目了')
        return
      }
      let id
      if(key=='prev'){
        id = this.info.prevProjectId
      }else{
        id = this.info.nextProjectId
      }
      this.$router.push(`/review/info?id=${id}`)
      this.getInfo(id)
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    // 显示评分标准
    showScore(){
      this.isScore = !this.isScore
      if(this.isScore){
      this.$nextTick(function () {
        let el = document.getElementById('score')
        window.scrollTo({"behavior":"smooth","top":el.offsetTop});
      })
      }
    },
    // 查看图片
    showImg(key, title, index){
      this.modalTitle = title
      this.imgList = this.info[key]
      this.isShow = true
      if(index){
        this.index = index
      }else{
        this.index = 0
      }
      // 往历史记录里面添加一条新的当前页面的url
      window.history.pushState(null,null,document.URL);
    },
    // 获取参评项目信息
    getInfo(id) {
      this.score = {
        score1: 0,
        score2: 0,
        score3: 0,
        score4: 0,
        score5: 0,
        score6: 0
      }
      Service.getProjectInfo(id)
        .then(res => {
          if (res.errorNo == 0) {
            let data = res.data
            if(data.acceptanceFile&&data.acceptanceFile.length>0){
              let acceptancePdf = [], acceptanceImg = []
              for(let i=0,l=data.acceptanceFile.length;i<l;i++){
                if(data.acceptanceFile[i].fileType=='FILE'){
                  acceptancePdf.push(data.acceptanceFile[i])
                }else{
                  acceptanceImg.push(data.acceptanceFile[i])
                }
              }
              data.acceptancePdf = acceptancePdf
              data.acceptanceImg = acceptanceImg
            }

            if(data.methodFile&&data.methodFile.length>0){
              let methodPdf = [], methodImg = []
              for(let i=0,l=data.methodFile.length;i<l;i++){
                if(data.methodFile[i].fileType=='FILE'){
                  methodPdf.push(data.methodFile[i])
                }else{
                  methodImg.push(data.methodFile[i])
                }
              }
              data.methodPdf = methodPdf
              data.methodImg = methodImg
            }
            
            if(data.standardFile&&data.standardFile.length>0){
              let standardPdf = [], standardImg = []
              for(let i=0,l=data.standardFile.length;i<l;i++){
                if(data.standardFile[i].fileType=='FILE'){
                  standardPdf.push(data.standardFile[i])
                }else{
                  standardImg.push(data.standardFile[i])
                }
              }
              data.standardPdf = standardPdf
              data.standardImg = standardImg
            }
            
            if(data.patentFile&&data.patentFile.length>0){
              let patentPdf = [], patentImg = []
              for(let i=0,l=data.patentFile.length;i<l;i++){
                if(data.patentFile[i].fileType=='FILE'){
                  patentPdf.push(data.patentFile[i])
                }else{
                  patentImg.push(data.patentFile[i])
                }
              }
              data.patentPdf = patentPdf
              data.patentImg = patentImg
            }
            
            if(data.achievementsFile&&data.achievementsFile.length>0){
              let achievementsPdf = [], achievementsImg = []
              for(let i=0,l=data.achievementsFile.length;i<l;i++){
                if(data.achievementsFile[i].fileType=='FILE'){
                  achievementsPdf.push(data.achievementsFile[i])
                }else{
                  achievementsImg.push(data.achievementsFile[i])
                }
              }
              data.achievementsPdf = achievementsPdf
              data.achievementsImg = achievementsImg
            }
            
            if(data.rewardFile&&data.rewardFile.length>0){
              let rewardPdf = [], rewardImg = []
              for(let i=0,l=data.rewardFile.length;i<l;i++){
                if(data.rewardFile[i].fileType=='FILE'){
                  rewardPdf.push(data.rewardFile[i])
                }else{
                  rewardImg.push(data.rewardFile[i])
                }
              }
              data.rewardPdf = rewardPdf
              data.rewardImg = rewardImg
            }
            
            if(data.evaluateFile&&data.evaluateFile.length>0){
              let evaluatePdf = [], evaluateImg = []
              for(let i=0,l=data.evaluateFile.length;i<l;i++){
                if(data.evaluateFile[i].fileType=='FILE'){
                  evaluatePdf.push(data.evaluateFile[i])
                }else{
                  evaluateImg.push(data.evaluateFile[i])
                }
              }
              data.evaluatePdf = evaluatePdf
              data.evaluateImg = evaluateImg
            }
            
            if(data.recommendFile&&data.recommendFile.length>0){
              let recommendPdf = [], recommendImg = []
              for(let i=0,l=data.recommendFile.length;i<l;i++){
                if(data.recommendFile[i].fileType=='FILE'){
                  recommendPdf.push(data.recommendFile[i])
                }else{
                  recommendImg.push(data.recommendFile[i])
                }
              }
              data.recommendPdf = recommendPdf
              data.recommendImg = recommendImg
            }
            
            if(data.prizeFile&&data.prizeFile.length>0){
              let prizePdf = [], prizeImg = []
              for(let i=0,l=data.prizeFile.length;i<l;i++){
                if(data.prizeFile[i].fileType=='FILE'){
                  prizePdf.push(data.prizeFile[i])
                }else{
                  prizeImg.push(data.prizeFile[i])
                }
              }
              data.prizePdf = prizePdf
              data.prizeImg = prizeImg
            }
            
            this.info = data;
            if(this.info.scoreInfo){
              this.score = this.info.scoreInfo
              this.isScore = true
            }
          } else {
            this.info = {};
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.infoWrap{
  padding: 30px;
  font-size: 16px;
  line-height: 1.8em;
  .empty{
    text-align: center;
    padding: 30px 0;
  }
  .cover{
    width: 100%;
  }
  .name{
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
  }
  .info{
    padding-top: 15px;
  }
  .item{
    padding-top: 15px;
    .label {
      float: left;
      width: 80px;
      font-weight: bold;
      padding-bottom: 5px;
    }
    .content {
      margin-left: 85px;
      color: #777;
      word-break:break-all;
    }
    .imgBtn{
      border: 2px solid #277a44;
      color: #277a44;
      background: #F3F3F3;
      margin: 8px 0 10px;
    }
  }
  
  .imgWrap{
    position: relative;
    padding-bottom: 60%;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 1px solid #eee;
    .img{
      position: absolute;
      left: 0;
      top: 0;
    }
    .mask {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      .bigBtn {
        display: inline-block;
        width: 30px;
        height: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -15px;
        margin-left: -15px;
        cursor: pointer;
        background: url(/img/enlarge.png) no-repeat;
        background-size: 30px;
      }
    }
  }
  .imgWrap:hover .mask {
    display: inline-block;
  }
  .btnWrap{
    padding: 30px 0;
    text-align: center;

    .submitBtn{
      padding-left: 30px;
      padding-right: 30px;
    }
    .textBtn{
      margin: 0 15px;
      color: #277a44;
    }
  }
  .scoreWrap{
    background: #fff;
    padding: 30px;
    border-radius: 10px;

    .subTitle{
      text-align: center;
      font-weight: bold;
    }
    .scoreText{
      position: relative;
      padding: 10px 15px 10px 90px;
      margin: 10px 0;
      background: #FAFAFA;
    }
    .score{
      position: relative;
      padding: 15px 50px 40px 90px;
      margin-bottom: 15px;
      .scoreRight{
        position: relative;
        .scoreItem{
          display: inline-block;
          width: 5%;
          padding: 0 2px;
          .bgItem{
            height: 20px;
            background: #E7E7E7;
            cursor: pointer;
          }
          .bgItemActive{
            background: #277a44;
          }
        }
        .line{
          border-top: 3px solid;
          text-align: center;
          position: absolute;
        }
        .aLine{
          border-color:#FE9C9C;
        }
        .bLine{
          border-color:#FED39C;
        }
        .cLine{
          border-color:#F4E44D;
        }
        .dLine{
          border-color:#9CA9FE;
        }
        .eLine{
          border-color:#DF94FA;
        }
        .one{
          width: 5%;
        }
        .two{
          width: 10%;
        }
        .three{
          width: 15%;
        }
        .four{
          width: 20%;
        }
        .five{
          width: 25%;
        }
        .ten{
          width: 50%;
        }
        .leftZero{
          left: 0;
        }
        .leftFive{
          left: 25%;
        }
        .leftTen{
          left: 50%;
        }
        .leftFour{
          left: 20%;
        }
        .leftNine{
          left: 45%;
        }
        .leftEleven{
          left: 55%;
        }
        .leftFourteen{
          left: 70%;
        }
        .leftFifteen{
          left: 75%;
        }
        .leftNineteen{
          left: 95%;
        }
      }
      .result{
        position: absolute;
        right: 0;
        top: 10px;
        font-weight: bold;
        color: #277a44;
      }
    }
    .bdBtm{
      border-bottom: 1px solid #E1E1E1;
    }
    .note{
      position: absolute;
      left: 15px;
      top: 10px;
      width: 70px;
    }
    .totalResult{
      text-align: right;
      font-size: 18px;
      font-weight: bold;
      color: #277a44;
      padding-bottom: 20px;
    }
  }
}
.modalBtnWrap{
  padding: 30px 0 20px;
  text-align: center;
  .orangeBtn{
    background: #FB9840;
    color: #fff;
    margin: 0 15px;
  }
}

@media screen and (max-width:768px) {
  .infoWrap{
    padding: 15px;
    .item{
      .label{
        float: none;
        width: 100%;
      }
      .content{
        margin-left: 0;
      }
    }
    .imgWrap .mask{
      display: inline-block;
    }
    .btnWrap .textBtn{
      margin: 0 5px;
    }
    .scoreWrap{
      padding: 15px 10px;
      .scoreText{
        padding-left: 10px;
        padding-right: 10px;
      }
      .score{
        padding-left: 0;
        padding-right: 0;
      }
      .note{
        position: static;
        padding-bottom: 10px;
        width: 100%;
      }
    }
  }
}
</style>