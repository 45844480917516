<template>
  <div class="wrap">
    <p ref="contentEl" class="space" :class="flag?'max-three-line':''">{{content}}</p>
    <div v-if="!hideBtn" class="BtnWrap" :class="{'unfold': !flag}">
      <span class="clickBtn" @click="flag = !flag">{{flag?'展开':'收起'}}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "hasMoreDiv",
    props: {
      //内容文本
      content: {
        type: String,
        default: function () {
          return "";
        },
      },
    },
    data() {
      return {
        flag: false, //是否隐藏内容
        hideBtn: false
      };
    },
    mounted() {
      let el = this.$refs.contentEl
      let elHeight = el.offsetHeight
      //三行的高度为62
      this.hideBtn = elHeight <= 62
      //测量之后,修改flag,设置样式
      this.flag = true
    }
  }
</script>

<style lang="scss" scoped>
  .space{
    white-space: pre-line;
  }
  .max-three-line {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .wrap{
    position: relative;
    .BtnWrap{
      position: absolute;
      right: 0;
      bottom: 0;
      background-image:linear-gradient(
        to right,
        rgba($color: #F3F3F3, $alpha: .6) 0%,
        rgba($color: #F3F3F3, $alpha: 1) 50%,
        rgba($color: #F3F3F3, $alpha: 1) 100%
      );
      width: 150px;
      text-align: right;
      .clickBtn{
        color: #277a44;
        cursor: pointer;
      }
    }
    .unfold{
      position: relative;
      width: 100%;
    }
  }
</style>